.register-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 123px;
}

.register-form .google-register-btn {
    font-weight: 300;
    font-size: 35px;
    line-height: 50.68px;
    display: flex;
    border: 3px solid #F23E4D;
    border-radius: 40px;
    background-image: url('../../../public/images/Google.png');
    background-repeat: no-repeat;
    background-position: 11% 50%;
    padding: 4px 89px;
    text-align: center;
    align-items: center;
    color: #F23E4D;
}

.register-form .google-register-btn:hover {
    background-color: #F23E4D;
    color: #fff;
}

.register-form .or {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
}

.register-form .field-group {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.register-form .or .line {
    width: 122.5px;
    height: 2px;
    background: #934F4C;
}

.register-form .or .quote {
    font-weight: 700;
    font-size: 30px;
    line-height: 43.44px;
    color: #934F4C;
}

.register-form .field {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.register-form .field .label {
    font-weight: 300;
    font-size: 30px;
    line-height: 43px;
    color: #F23E4D;
}

.register-form .field input {
    background-color: transparent;
    width: calc(100% - 40px);
    height: 16px;
    font-weight: 300;
    font-size: 22px;
    padding: 20px;
    border: 1px solid #934F4C;
    border-radius: 26px;
    margin-bottom: 24px;
}

.register-form .space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.register-form .space-between .remember {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.register-form .space-between .remember input[type=checkbox] {
    width: 24px;
    height: 24px;
    accent-color: #F23E4D;
    margin-right: 10px;
}

.register-form .space-between .remember span {
    font-weight: 300;
    font-size: 30px;
    line-height: 43px;
}

.register-form .space-between a {
    font-weight: 300;
    font-size: 25px;
    line-height: 36px;
    color: #F23E4D;
}

.register-form .register-btn {
    width: 100%;
    background: #F23E4D;
    border-radius: 32px;
    opacity: 0.25;
    color: #fff;
    font-weight: 400;
    font-size: 40px;
    line-height: 57.92px;
    text-align: center;
    margin-top: 10px;
}

.register-form .register-btn:hover {
    opacity: 1;
}
